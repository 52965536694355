<template>
  <div v-if="logading">
    <quill-editor
      :class="`editor ` + refName"
      :ref="refName"
      v-model="dataModel"
      :options="editorOption"
      @change="dataOut"
    >
    </quill-editor>
  </div>
</template>
<script>
import { TERAK } from "@/service/axios";

import { quillEditor } from "vue-quill-editor";
import Quill from "quill";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import AutoLinks from "quill-auto-links";

import htmlEditButton from "quill-html-edit-button";

import quillImageDropAndPaste from "@/service/quillImageDropAndPaste";
Quill.register("modules/quillImageDropAndPaste", quillImageDropAndPaste);
import "quill/dist/quill.snow.css";
import "element-ui/lib/theme-chalk/display.css";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
const quillTable = require("quill-table");
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);
Quill.register("modules/autoLinks", AutoLinks);
Quill.register("modules/htmlEditButton", htmlEditButton);

const maxRows = 4;
const maxCols = 5;
const tableOptions = [];
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push("newtable_" + r + "_" + c);
  }
}

import undo_icon from "@/assets/iconExport.png";
import redo_icon from "@/assets/iconExport.png";
import full_screen from "@/assets/fullscreen.svg";

import jwt from "jsonwebtoken";
export default {
  components: { quillEditor },
  props: ["data", "name", "pageName"],
  watch: {
    data() {
      this.checkName();
    },
  },
  mounted() {
    const self = this;
    var icons = Quill.import("ui/icons");
    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

    icons["fullScreen"] = `<img src="${full_screen}"/>`;
    this.checkName();
  },
  data() {
    let self = this;
    return {
      logading: false,
      refName: "",
      refQuill: "",
      dataModel: "",
      imgUrlTERAK: process.env.VUE_APP_BASE_URL_TERAK,
      editorOption: {
        placeholder: "",
        bounds: ".editor",
        modules: {
          htmlEditButton: {
            // debug: true, // logging, default:false
            msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: "Ok", // Text to display in the OK button, default: Ok,
            cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
            buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
            buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {}, // The default mod
          },
          history: {
            delay: 1000,
            maxStack: 100,
            userOnly: false,
          },
          quillImageDropAndPaste: {
            handler: this.addRefQuill,
          },
          autoLinks: true,
          table: true,
          imageResize: {
            displaySize: true,
          },
          imageDrop: true,
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ color: [] }, { background: [] }],
              [{ direction: "rtl" }, "link"],
              [
                { table: tableOptions },
                { table: "append-row" },
                { table: "append-col" },
              ],
              [
                {
                  undo: undo_icon,
                },
                {
                  redo: redo_icon,
                },
                {
                  fullScreen: full_screen,
                },
              ],

              ["clean"],
            ],
            handlers: {
              redo() {
                this.quill.history.redo();
              },
              undo() {
                this.quill.history.undo();
              },
              fullScreen() {
                if (self.$el.className == "") {
                  self.$el.classList.add("editor-full");
                } else {
                  self.$el.classList.remove("editor-full");
                }
              },
            },
          },
        },
      },
    };
  },
  methods: {
    checkName() {
      if (this.pageName == "credit") {
        if (this.name == "attributeProductService") {
          this.refName = "attributeProductService";
          this.dataModel = this.data.attributeProductService;
        } else if (this.name == "taxOrIncrease") {
          this.dataModel = this.data.taxOrIncrease;
          this.refName = "taxOrIncrease";
        } else if (this.name == "limitMaximum") {
          this.dataModel = this.data.limitMaximum;
          this.refName = "limitMaximum";
        } else if (this.name == "guarantee") {
          this.dataModel = this.data.guarantee;
          this.refName = "guarantee";
        } else if (this.name == "property") {
          this.dataModel = this.data.property;
          this.refName = "property";
        } else if (this.name == "condition") {
          this.dataModel = this.data.condition;
          this.refName = "condition";
        } else if (this.name == "description") {
          this.dataModel = this.data.description;
          this.refName = "description";
        } else if (this.name == "detailsEight") {
          this.dataModel = this.data.detailsEight;
          this.refName = "detailsEight";
        } else if (this.name == "detailsNine") {
          this.dataModel = this.data.detailsNine;
          this.refName = "detailsNine";
        }
      } else if (this.pageName == "insurance") {
        if (this.name == "insuranceOverview") {
          this.refName = "insuranceOverview";
          this.dataModel = this.data.insuranceOverview;
        } else if (this.name == "insuranceCondition") {
          this.refName = "insuranceCondition";
          this.dataModel = this.data.insuranceCondition;
        } else if (this.name == "protectedRisk") {
          this.refName = "protectedRisk";
          this.dataModel = this.data.protectedRisk;
        } else if (this.name == "converageRate") {
          this.refName = "converageRate";
          this.dataModel = this.data.converageRate;
        } else if (this.name == "insuranceCost") {
          this.refName = "insuranceCost";
          this.dataModel = this.data.insuranceCost;
        } else if (this.name == "insuranceClaimPay") {
          this.refName = "insuranceClaimPay";
          this.dataModel = this.data.insuranceClaimPay;
        } else if (this.name == "serviceRequestProcess") {
          this.refName = "serviceRequestProcess";
          this.dataModel = this.data.serviceRequestProcess;
        } else if (this.name == "specialCondition") {
          this.refName = "specialCondition";
          this.dataModel = this.data.specialCondition;
        } else if (this.name == "detailsNine") {
          this.refName = "detailsNine";
          this.dataModel = this.data.detailsNine;
        }
      } else if (this.pageName == "project") {
        if (this.name == "projectDescription") {
          this.refName = "projectDescription";
          this.dataModel = this.data.projectDescription;
        }
      }
      this.logading = true;
    },
    addRefQuill(imageDataUrl, type, imageData) {
      if (this.pageName == "credit") {
        if (this.name == "attributeProductService") {
          this.refQuill = this.$refs.attributeProductService.quill;
        } else if (this.name == "taxOrIncrease") {
          this.refQuill = this.$refs.taxOrIncrease.quill;
        } else if (this.name == "limitMaximum") {
          this.refQuill = this.$refs.limitMaximum.quill;
        } else if (this.name == "guarantee") {
          this.refQuill = this.$refs.guarantee.quill;
        } else if (this.name == "property") {
          this.refQuill = this.$refs.property.quill;
        } else if (this.name == "condition") {
          this.refQuill = this.$refs.condition.quill;
        } else if (this.name == "description") {
          this.refQuill = this.$refs.description.quill;
        } else if (this.name == "detailsEight") {
          this.refQuill = this.$refs.detailsEight.quill;
        } else if (this.name == "detailsNine") {
          this.refQuill = this.$refs.detailsNine.quill;
        }
      } else if (this.pageName == "insurance") {
        if (this.name == "insuranceOverview") {
          this.refQuill = this.$refs.insuranceOverview.quill;
        } else if (this.name == "insuranceCondition") {
          this.refQuill = this.$refs.insuranceCondition.quill;
        } else if (this.name == "protectedRisk") {
          this.refQuill = this.$refs.protectedRisk.quill;
        } else if (this.name == "converageRate") {
          this.refQuill = this.$refs.converageRate.quill;
        } else if (this.name == "insuranceCost") {
          this.refQuill = this.$refs.insuranceCost.quill;
        } else if (this.name == "insuranceClaimPay") {
          this.refQuill = this.$refs.insuranceClaimPay.quill;
        } else if (this.name == "serviceRequestProcess") {
          this.refQuill = this.$refs.serviceRequestProcess.quill;
        } else if (this.name == "specialCondition") {
          this.refQuill = this.$refs.specialCondition.quill;
        } else if (this.name == "detailsNine") {
          this.refQuill = this.$refs.detailsNine.quill;
        }
      } else if (this.pageName == "project") {
        if (this.name == "projectDescription") {
          this.refQuill = this.$refs.projectDescription.quill;
        }
      }
      //console.log(imageDataUrl, type, imageData);
      let quill = this.refQuill;
      var filename = "my_cool_image.png";
      var file = imageData.toFile(filename);
      this.uploadImageHandler(quill, file, filename);
    },
    async uploadImageHandler(quill, file, filename) {
      let ext = file.name.split(".").pop().toLowerCase();
      if (
        (ext === "jpg" ||
          ext === "jpeg" ||
          ext === "gif" ||
          ext === "png" ||
          ext === "webp") &&
        file.size > 25000000
      ) {
        this.alertUploadFileSizeError();
      } else {
        // let urlFile = this.fileUrl + this.task._id + "/";
        var formData = new FormData();
        formData.append("file", file);
        var token = jwt.sign(
          { system: "CFOE2021" },
          "Y2weQEzYjje3LRDiwAUiX1U2r",
          { expiresIn: "5m" }
        );
        TERAK.defaults.headers.common.Authorization = token;
        let res = await TERAK.post(`cfoe/file`, formData);
        if (res.data.success) {
          let index = (quill.getSelection() || {}).index;
          if (index === undefined || index < 0) index = quill.getLength();
          quill.insertEmbed(
            index,
            "image",
            this.imgUrlTERAK + "get/file/cfoe/" + res.data.filename
          );
          quill.setSelection(index + 1);
        }
      }
    },
    dataOut() {
      if (this.pageName == "credit") {
        if (this.name == "attributeProductService") {
          this.data.attributeProductService = this.dataModel;
        } else if (this.name == "taxOrIncrease") {
          this.data.taxOrIncrease = this.dataModel;
        } else if (this.name == "limitMaximum") {
          this.data.limitMaximum = this.dataModel;
        } else if (this.name == "guarantee") {
          this.data.guarantee = this.dataModel;
        } else if (this.name == "property") {
          this.data.property = this.dataModel;
        } else if (this.name == "condition") {
          this.data.condition = this.dataModel;
        } else if (this.name == "description") {
          this.data.description = this.dataModel;
        } else if (this.name == "detailsEight") {
          this.data.detailsEight = this.dataModel;
        } else if (this.name == "detailsNine") {
          this.data.detailsNine = this.dataModel;
        }
      } else if (this.pageName == "insurance") {
        if (this.name == "insuranceOverview") {
          this.data.insuranceOverview = this.dataModel;
        } else if (this.name == "insuranceCondition") {
          this.data.insuranceCondition = this.dataModel;
        } else if (this.name == "protectedRisk") {
          this.data.protectedRisk = this.dataModel;
        } else if (this.name == "converageRate") {
          this.data.converageRate = this.dataModel;
        } else if (this.name == "insuranceCost") {
          this.data.insuranceCost = this.dataModel;
        } else if (this.name == "insuranceClaimPay") {
          this.data.insuranceClaimPay = this.dataModel;
        } else if (this.name == "serviceRequestProcess") {
          this.data.serviceRequestProcess = this.dataModel;
        } else if (this.name == "specialCondition") {
          this.data.specialCondition = this.dataModel;
        } else if (this.name == "detailsNine") {
          this.data.detailsNine = this.dataModel;
        }
      } else if (this.pageName == "project") {
        if (this.name == "projectDescription") {
          this.data.projectDescription = this.dataModel;
        }
      }
      // this.$emit("dataOuta", this.data);
    },
  },
};
</script>